import { cloneElement } from 'react';
import ReactCountdown from 'react-countdown';
import { useInView } from 'react-intersection-observer';
import classnames from 'classnames/bind';
import dayjs from 'dayjs';

import { Link } from 'components/link/Link';

import s from './Countdown.module.scss';

const c = classnames.bind(s);

type Props = {
  image?: React.ReactElement;
  link?: string;
  linkText?: string;
  date: string;
  message: React.ReactElement;
};

export const Countdown = ({ date, image, link, linkText, message }: Props) => {
  const { ref, inView } = useInView();
  const future = dayjs(date);
  if (!future.isValid()) {
    console.warn(`Unable to parse date: ${date}`);
    return null;
  }

  return (
    <ReactCountdown
      date={future.toDate()}
      daysInHours={false}
      zeroPadDays={1}
      renderer={({ days, hours, minutes, seconds, completed }) => {
        return (
          <div className={c(s.countdown, { completed, inView })} ref={ref}>
            {image && cloneElement(image, { className: s.countdown__image })}
            <div className={s.countdown__inner}>
              {completed && link && (
                <Link to={link} className={s.countdown__link}>
                  {linkText ?? 'Sjá nánar'}
                </Link>
              )}
              {!completed && (
                <>
                  <ul className={c(s.countdown__list)}>
                    <li className={s.countdown__item}>
                      <div className={s.countdown__number}>{days}</div>
                      <div className={s.countdown__label}>Dagar</div>
                    </li>
                    <li className={s.countdown__item}>
                      <div className={s.countdown__number}>{hours}</div>
                      <div className={s.countdown__label}>Klukku&shy;stundir</div>
                    </li>
                    <li className={s.countdown__item}>
                      <div className={s.countdown__number}>{minutes}</div>
                      <div className={s.countdown__label}>Mínútur</div>
                    </li>
                    <li className={s.countdown__item}>
                      <div className={s.countdown__number}>{seconds}</div>
                      <div className={s.countdown__label}>Sekúndur</div>
                    </li>
                  </ul>
                  {cloneElement(message, {
                    className: s.countdown__message,
                  })}
                </>
              )}
            </div>
          </div>
        );
      }}
    />
  );
};
