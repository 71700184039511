import { linkResolver } from 'prismic/linkResolver';
import { PrismicImage } from 'prismic/types/image';
import { PrismicLinkWithMeta } from 'prismic/types/link';
import { PrismicRichText } from 'prismic/types/richtext';
import {
  EducationSlicesCount_Down,
  FrontpageSlicesCount_Down,
  NewsSlicesCount_Down,
  PageSlicesCount_Down,
} from 'prismic-types';

import { Countdown } from 'components/countdown/Countdown';
import { RegularOrCroppableImage } from 'components/picture/RegularOrCroppablePicture';
import { asText, RichText } from 'components/rich-text/RichText';

import { generateImageSizes } from 'utils/cleverCropping';

type Props = {
  date: string;
  image: PrismicImage;
  link: PrismicLinkWithMeta;
  linkText: PrismicRichText;
  message: PrismicRichText;
};

export function CountDownSlice({ date, image, link, linkText, message }: Props) {
  return (
    <Countdown
      date={date}
      image={
        image ? (
          <RegularOrCroppableImage
            image={image}
            componentSizes={generateImageSizes({
              ratio: 761 / 1740,
              wide: 1740,
              mobile: 680,
            })}
          />
        ) : undefined
      }
      link={linkResolver(link)}
      linkText={asText(linkText)}
      message={<RichText>{message}</RichText>}
    />
  );
}

export function prismicSliceToCountDown(
  s:
    | PageSlicesCount_Down
    | FrontpageSlicesCount_Down
    | NewsSlicesCount_Down
    | EducationSlicesCount_Down
) {
  return (
    <CountDownSlice
      image={(s.variation?.primary?.image ?? null) as PrismicImage}
      link={s.variation?.primary?.link as PrismicLinkWithMeta}
      linkText={s.variation?.primary?.linkText as PrismicRichText}
      message={s.variation?.primary?.text as PrismicRichText}
      date={s.variation?.primary?.date}
    />
  );
}
