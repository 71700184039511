import React from 'react'

import s from './PricesItem.module.scss'

type Props = {
  title: string
  number?: string
  unit: string

  hidepowerpackages?: boolean
}

const PricesItem = (props: Props) => {
  const { title, number, unit, hidepowerpackages } = props

  return (
    <div className={s.pricesItem}>
      <div className={s.pricesItem__title}>{title}</div>

      <div
        className={
          hidepowerpackages ? s.pricesItem__bigNumber : s.pricesItem__number
        }
      >
        {number}
      </div>
      <div>{unit}</div>
    </div>
  )
}

export default PricesItem
