import React from 'react'

import s from './PricesItem.module.scss'
import PricesItem from './PricesItem'
import { usePowerPackageData } from 'hooks/usePowerPackageData'
import {
  formatPricesOnPackages,
  hasPackageTwoPrices,
} from 'utils/powerPackageHelper'
import { QueryResponseType } from 'pages/api/get-power-packages'

export const powerPackageDataUnit = 'Kr. / kWh'

const PricesOfPackagesList = () => {
  const { powerPackageData } = usePowerPackageData()

  const moveNightPowerToLast = (powerPackageData: QueryResponseType) => {
    if (!powerPackageData) return
    const nightPower = powerPackageData?.allPower_packages.edges.find(
      (edge) => edge.node.package_name === 'NæturOrka'
    )

    if (nightPower) {
      const nightPowerIndex =
        powerPackageData?.allPower_packages.edges.findIndex(
          (edge) => edge.node.package_name === 'NæturOrka'
        )

      powerPackageData?.allPower_packages.edges.splice(nightPowerIndex, 1)
      powerPackageData?.allPower_packages.edges.push(nightPower)
    }
  }

  if (powerPackageData) {
    moveNightPowerToLast(powerPackageData)
  }

  return (
    <>
      {powerPackageData &&
        powerPackageData?.allPower_packages.edges.map((edge) => {
          const {
            package_name,
            package_second_price,
            first_price_title,
            package_first_price,
            second_price_title,
          } = edge.node

          const hasTwoPrices = hasPackageTwoPrices(first_price_title)
          if (hasTwoPrices) {
            return (
              <PricesItem
                title={package_name}
                number={formatPricesOnPackages(package_second_price)}
                unit={powerPackageDataUnit}
                key={`price-on-packages-${package_name}`}
              />
            )
          }
          return (
            <div
              className={s.pricesItem}
              key={`price-on-packages-${package_name}`}
            >
              <div className={s.twoPricesName}>{package_name}</div>
              <div className={s.twoPrices}>
                <div>
                  <span className={s.pricesItem__eyebrow}>
                    {second_price_title}
                  </span>
                  <div className={s.pricesItem__number}>
                    {formatPricesOnPackages(package_second_price)}
                  </div>
                  <div>{powerPackageDataUnit}</div>
                </div>
                <div>
                  <span className={s.pricesItem__eyebrow}>
                    {first_price_title}
                  </span>
                  <div className={s.pricesItem__number}>
                    {formatPricesOnPackages(package_first_price)}
                  </div>
                  <div>{powerPackageDataUnit}</div>
                </div>
              </div>
            </div>
          )
        })}
    </>
  )
}

export default PricesOfPackagesList
