import React from 'react'
import { PrismicRichText } from 'prismic/types/richtext'
import {
  EducationSlicesPrices,
  FrontpageSlicesPrices,
  NewsSlicesPrices,
  PageSlicesPrices,
  PageSlicesPricesDefaultItems,
} from 'prismic-types'

import Prices from 'components/prices/Prices'
import PricesItem from 'components/prices/PricesItem'
import { asText, RichText } from 'components/rich-text/RichText'

type Props = {
  title: PrismicRichText
  intro: PrismicRichText
  hidepowerpackages?: boolean
  info?: PrismicRichText
  items?: Array<PageSlicesPricesDefaultItems>
}

export const PricesSlice = (props: Props) => {
  const { title, intro, info, items, hidepowerpackages = false } = props

  if (!asText(title) || !asText(intro) || !items) {
    return null
  }

  return (
    <Prices
      title={asText(title)}
      hidepowerpackages={hidepowerpackages}
      intro={<RichText>{intro}</RichText>}
      info={<RichText>{info}</RichText>}
    >
      {items
        .filter(
          (item) =>
            asText(item.titleItem) && item.number && asText(item.unitFormat)
        )
        .map((item, index) => {
          return (
            <PricesItem
              key={index}
              title={asText(item.titleItem)}
              number={item.number?.toString().replace('.', ',')}
              unit={asText(item.unitFormat)}
              hidepowerpackages={hidepowerpackages}
            />
          )
        })}
    </Prices>
  )
}

export const prismicSliceToPrices = (
  s:
    | PageSlicesPrices
    | FrontpageSlicesPrices
    | NewsSlicesPrices
    | EducationSlicesPrices
) => {
  const items = (s.variation?.items ??
    null) as Array<PageSlicesPricesDefaultItems>

  if (!items.length) {
    return null
  }

  return (
    <PricesSlice
      title={(s.variation?.primary?.title ?? null) as PrismicRichText}
      intro={(s.variation?.primary?.intro ?? null) as PrismicRichText}
      hidepowerpackages={
        s.variation?.primary?.hidepowerpackages ?? (false as boolean)
      }
      info={(s.variation?.primary?.info ?? null) as PrismicRichText}
      items={items}
    />
  )
}
