export const formatPowerPackageTitle = (title: string) => {
  const orkaIndex = title.indexOf('Orka')
  const preTitle = orkaIndex === -1 ? title : title.slice(0, orkaIndex)
  const postTitle = orkaIndex === -1 ? '' : title.slice(orkaIndex)

  return { preTitle, postTitle }
}

export const formatPricesOnPackages = (price: number) => {
  const formattedPrice = price.toFixed(2)
  return formattedPrice.replaceAll('.', ',')
}

export const hasPackageTwoPrices = (firstPriceTitle: string) => {
  // TODO: minor boolean check for price coloring for 'næturverð' and 'dagverð'
  return firstPriceTitle !== 'Dagtaxti'
}
