import { useRouter } from 'next/router'
import { QueryResponseType } from 'pages/api/get-power-packages'
import { useEffect, useState } from 'react'
import { localeToPrismicLocale } from 'utils/i18n'

export const usePowerPackageData = () => {
  const router = useRouter()
  const locale = localeToPrismicLocale(router.locale)
  const [powerPackageData, setPowerPackageData] = useState<QueryResponseType>()

  useEffect(() => {
    const fetchData = async () => {
      const fetchUrl = `/api/get-power-packages?lang=${locale}`
      const res = await fetch(fetchUrl)
      const { data }: { data: QueryResponseType } = await res.json()

      setPowerPackageData(data)
    }
    fetchData()
  }, [])

  return {
    powerPackageData,
  }
}
